<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('common.select.contact-person')">
            <div v-if="!loading" class="px-3 py-2">
                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="contact-persons"
                                      columnsPrefix="contact-persons.column."
                                      :customSource="getThis()"
                                      :items="myProvider"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :actions-list="getActions()"
                            >
                                <template #cell(selected)="data">
                                    <div>
                                        <b-form-checkbox :key="data.item.id"
                                                         @change="selectRow($event, data.item)"
                                                         :checked="selected.includes(data.item)"
                                                         v-if="data.item.email"
                                        ></b-form-checkbox>
                                    </div>
                                </template>

                                <template #cell(id)="row">
                                    <a href="#"
                                       @click.prevent="$root.$children[0].openModal('contact-person-form', {id:row.item.id}, refreshTable)">
                                        {{ row.item.id }}
                                    </a>
                                </template>

                                <template #cell(actions)="row">
                                    <b-dropdown class="mb-1" left variant="primary" size="sm">
                                        <template #button-content>
                                            {{ $t('common.label.actions') }}
                                        </template>

                                        <b-dropdown-item
                                            @click.prevent="$root.$children[0].openModal('contact-person-form', {id:row.item.id}, refreshTable)">
                                            <font-awesome-icon class="mr-2" icon="edit"/>
                                            {{ $t('common.button.edit') }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>

                                <template #cell(total)=" row
                                        ">
                                    <b-button size="sm" @click="select(row.item.id)" variant="success">
                                        {{ $t('common.button.select') }}
                                    </b-button>
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <div class="form-actions">
                        <b-row>
                            <b-col sm="12" class="text-xs-center">
                                <b-button :disabled="selected.length!=1" block @click="select()" variant="primary">
                                    {{ $t('common.button.select') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from "@/bundles/erika_common_bundle/mixins/table-date-filter";

export default {
    name: 'SelectContactPersonModal',
    mixins: [commonSave, commonTable, tableDateFilter],
    data() {
        return {
            tableUniqKey: 'select-contact-person-modal',
            loading: true,
            payload: {},
            callback: null,
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('ContactPerson', ['getContactPersonsTable']),
        shown() {
            this.$set(this, 'selected', [])
            this.loading = false
        },
        select(id = null) {
            if (id == null) {
                id = this.selected[0].id
            }

            this.callback(id)

            this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
        },
        getThis() {
            return this
        },
        ctxAdditionalParams() {
            return this.payload.additionalParams
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'ContactPerson/fetchContactPersons', 'getContactPersonsTable');
        },
        getActions() {
            return []
        }
    },
}
</script>